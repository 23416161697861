/**
 * Creates a script and injects it into the DOM body.
 * @param url
 * @param {number} [maxWait=3000] - How long to wait for the promise to resolve (in milliseconds) before rejecting.
 * @returns {Promise}
 */
export const createScript = (url: string, maxWait = 3000): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onerror = () => reject();
    script.onload = () => resolve();
    document.body.appendChild(script);
    window.setTimeout(reject, maxWait);
  });

export const addGTMScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.textContent =
    '!function(e,t,a,n,g){e[n]=e[n]||[],e[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var m=t.getElementsByTagName(a)[0],r=t.createElement(a);r.async=!0,r.src="https://www.googletagmanager.com/gtm.js?id=GTM-52NRT4HS",m.parentNode.insertBefore(r,m)}(window,document,"script","dataLayer")';
  document.body.appendChild(script);
};

export const isSafariBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('safari') && !ua.includes('chrome');
};
