import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useAsync } from 'react-use';
import { ThemeProvider } from '@getgo/chameleon-web-react-wrapper';

import Core from 'components/core';
import ErrorFallback from 'components/error-fallback';
import config from 'config';
import Amplitude from 'lib/amplitude';
import AuthProvider from 'lib/auth-provider';
import { isSafariBrowser } from 'lib/dom-helpers';
import { hideGlobalLoading, setGlobalAccountKey, setGlobalSessionId } from 'modules/global-wrapper';
import store from 'store';
import { Theme } from 'types/global-wrapper';
import { SEARCH_PARAMS, supportedLocales } from 'utils/constants';

import '@getgo/chameleon-web/components/registerAll';

const App: FC = () => {
  const { pathname, search } = window.location;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const pathNameList = pathname.split('/');

  const [locale, setLocale] = useState('en_US');
  const [lang, setLang] = useState('en');
  const [theme, setTheme] = useState<Theme>('light');
  const [message, setMessage] = useState({} as Record<string, string>);

  const handleSearchParams = useCallback(
    (paramName: string, defaultValue: string) => {
      const localStorageValue = localStorage.getItem(paramName);
      const searchParamValue = searchParams.get(paramName) || '';

      // searchParam takes priority over the localStorage value
      if (searchParamValue) {
        let newValue = searchParamValue;

        if (paramName === SEARCH_PARAMS.locale) {
          // If the locale passed is not supported default it to en_US
          newValue = supportedLocales.includes(searchParamValue) ? searchParamValue : 'en_US';
        }

        localStorage.setItem(paramName, newValue);
      } else if (!localStorageValue) {
        localStorage.setItem(paramName, defaultValue);
      }

      searchParams.delete(paramName);

      const modifiedSearchParams = searchParams.toString();
      const newUrl = location.href.split('?')[0] + (modifiedSearchParams ? `?${modifiedSearchParams}` : '');
      history.pushState(null, '', newUrl);
    },
    [searchParams],
  );

  useEffect(() => {
    // If locale is not in localStorage or locale in localStorage is different from the current locale, set the locale
    handleSearchParams(SEARCH_PARAMS.locale, 'en_US');
    setLocale(localStorage.getItem(SEARCH_PARAMS.locale) || 'en_US');
  }, [handleSearchParams, searchParams]);

  useEffect(() => {
    // If theme is not in localStorage or theme in localStorage is different from the current theme, set the theme
    handleSearchParams(SEARCH_PARAMS.theme, 'light');
    setTheme((localStorage.getItem(SEARCH_PARAMS.theme) as Theme) || 'light');
  }, [handleSearchParams, searchParams]);

  useEffect(() => {
    // If partnerType is not in localStorage or partnerType in localStorage is different from the current partnerType, set the partnerType
    handleSearchParams(SEARCH_PARAMS.partnerType, '');
  }, [handleSearchParams, searchParams]);

  useEffect(() => {
    // If renewalDate is not in localStorage or renewalDate in localStorage is different from the current renewalDate, set the renewalDate
    handleSearchParams(SEARCH_PARAMS.renewalDate, '');
  }, [handleSearchParams, searchParams]);

  useEffect(() => {
    // accountKey
    const accountKey = pathNameList[1] || '';
    store.dispatch(setGlobalAccountKey(accountKey));

    // sessionId
    const sessionId = pathNameList[2] || '';
    store.dispatch(setGlobalSessionId(sessionId));

    // Initialize Amplitude
    accountKey && Amplitude.initialize(accountKey);

    // Wait for the global state to be set.
    store.dispatch(hideGlobalLoading());
  }, [pathNameList, pathname, searchParams]);

  // Load translations
  useAsync(async () => {
    const language = locale?.split('_')?.[0] || 'en';
    setLang(language);

    const message = await import(`translations/${locale}.json`);
    setMessage(message.default);
  }, [locale]);

  // Clear auth token from sessionStorage when user moves out of OnePay
  useEffect(() => {
    const onBeforeUnload = () => {
      const tokenName = config.AUTH_TOKEN_STORAGE_KEY;
      sessionStorage.removeItem(tokenName);
    };

    if (!isSafariBrowser()) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, []);

  return (
    <AuthProvider>
      <Provider store={store}>
        <IntlProvider locale={lang} messages={message}>
          <ThemeProvider className="app__theme-provider" theme={theme} skin="gotoadmin" rebranding2021>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <BrowserRouter>
                <Core />
              </BrowserRouter>
            </ErrorBoundary>
          </ThemeProvider>
        </IntlProvider>
      </Provider>
    </AuthProvider>
  );
};

export default App;
