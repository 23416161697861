import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { Alert, Typography } from '@getgo/chameleon-web-react-wrapper';

import st from 'utils/shared-translations';

import './error-fallback.css';

const ErrorFallback: FC<FallbackProps> = ({ error, ...otherProps }) => {
  const intl = useIntl();

  return (
    <div className="error-fallback" role="alert" {...otherProps}>
      <Alert variant="danger">
        <Typography>{intl.formatMessage(st['alert.error.general.refreshtryagain'])}</Typography>
        <Typography variant="caption-small-01">
          {intl.formatMessage(st['error.message'])}
          {': '}
          {error && error.message}
        </Typography>
      </Alert>
    </div>
  );
};

export default ErrorFallback;
