import config from 'config';
import { createScript } from 'lib/dom-helpers';

export interface FlexMicroformWrapper {
  createMicroform: (keyId: string) => Promise<void>;
  createFieldInstance: (fieldName: string, payload: object) => any;
  createToken: (payload: object, callback: Function) => void;
  teardown: () => void;
}

/**
 * @class CyberSourceFlexMicroformWrapper
 * See {@link https://developer.cybersource.com/api/developer-guides/dita-flex/SAFlexibleToken/FlexMicroform/api_reference.html} for more information.
 * @NOTE we need to update the version for this library manually
 * @NOTE we are currently using the 0.11.0
 */
export class CyberSourceFlexMicroformWrapper implements FlexMicroformWrapper {
  lib: Promise<void>;
  microformInstance: any;
  currentWindow: any;

  constructor(currentWindow = window) {
    const url = config.CYBERSOURCE.flex_url;
    this.lib = createScript(url);
    // This is the class instance that gets generated after the successful setup of flex microform.
    // It's used to interact the flex form as well as accessing its various methods
    this.microformInstance = {};
    this.currentWindow = currentWindow;
  }

  /**
   * This method initializes the Flex Microform.
   * If the Microform is successfully created, a MicroformInstance will be used to interact with the Microform's various methods.
   * Otherwise, an error will be returned
   *
   * @param {object} payload
   */
  createMicroform = (keyId) =>
    this.lib.then(
      () =>
        new Promise<void>((resolve, reject) => {
          try {
            const flex = new this.currentWindow.Flex(keyId);
            // Store a reference of the microformInstance so it can be used by other methods
            this.microformInstance = flex.microform();
            return resolve();
          } catch (error) {
            return reject(error);
          }
        }),
    );

  /**
   * Create a form field instance taken through microform
   * Takes a fieldname and payload which can have placeholder and custom style
   * Returns a field instance on which eventlistener can be added
   * @param {string} fieldName
   * @param {object} payload
   * @param {function} callback
   */
  createFieldInstance = (fieldName, payload) => this.microformInstance.createField(fieldName, payload);

  /**
   * Calls the createToken method on microformInstance to request a token
   * @param {function} callback
   */
  createToken = (payload, callback) => {
    if (typeof this.microformInstance.createToken === 'function') {
      this.microformInstance.createToken(payload, callback);
    }
  };

  /**
   * Calls the teardown method to destroy the microform instance
   */
  teardown = () => {
    if (typeof this.microformInstance.teardown === 'function') {
      this.microformInstance.teardown(() => {
        // Reset the microformInstance's value to an empty object to make sure that
        // the consumers are always interacting with the correct instance.
        this.microformInstance = {};
      });
    }
  };
}

export default new CyberSourceFlexMicroformWrapper();
