import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import config from 'config';
import packageInfo from '../package.json';
import App from './App';

import './index.css';

Sentry.init({
  dsn: 'https://14bac0f60bad110dd3f87c979600ca13@o173976.ingest.us.sentry.io/4508755120357376',
  environment: config.ENV,
  enabled: config.SENTRY_ENABLED,
  release: packageInfo.version,
  maxBreadcrumbs: 20,
});

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
